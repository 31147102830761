import { useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { XR, Controllers, Hands, VRButton, useXR } from "@react-three/xr";
import {
  PresentationControls,
  Environment,
  ScrollControls,
  useFBX,
  ContactShadows,
  Stage,
  useGLTF,
  OrbitControls,
  SoftShadows,
  Backdrop,
} from "@react-three/drei";
import { useLayoutEffect } from "react";
import { Box3, DoubleSide, FrontSide, Vector3 } from "three";
import { phongToStandard } from "../../utils/converter";

import QRCode from "react-qr-code";
import { Button } from "flowbite-react";
import { HiCube, HiOutlineArrowRight } from "react-icons/hi";
import {
  Icon3dCubeSphere,
  IconArrowsMinimize,
  IconScale,
} from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { IconArrowsMaximize } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";

export default function Viewer({
  path,
  vr = true,
  full,
  link = null,
  setFull,
  id,
  token,
}) {
  const [showQR, setShowQR] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      {!isMobile ? (
        <div className="absolute z-10 top-0 left-0 p-12">
          <a href={link}>
            <Button
              className="px-2 py-3 rounded-full bg-gray-100 hover:bg-gray-100"
              pill={true}
              onClick={() => setShowQR(!showQR)}
            >
              <Icon3dCubeSphere className="text-gray-900 p-0" />
            </Button>
          </a>
          {showQR && (
            <div className=" bg-gray-100 h-64 w-64 p-6 rounded-lg mt-6">
              <QRCode
                size={100}
                bgColor="#f3f4f6"
                style={{ height: "100%", width: "100%" }}
                value={`${window.location.host?.replace(
                  "http://",
                  "https://"
                )}/model/${id}?api_token=${token}`}
                viewBox={`0 0 256 256`}
              />
            </div>
          )}

          {/* {setFull &&
            (full ? (
              <Button
                className="px-2 py-3 mt-6 rounded-full bg-gray-100 hover:bg-gray-100"
                pill={true}
                onClick={() => setFull(false)}
              >
                <IconArrowsMinimize className="text-gray-900 p-0" />
              </Button>
            ) : (
              <Button
                className="px-2 py-3 mt-3 rounded-full bg-gray-100 hover:bg-gray-100"
                pill={true}
                onClick={() => setFull(true)}
              >
                <IconArrowsMaximize className="text-gray-900 p-0" />
              </Button>
            ))} */}
        </div>
      ) : (
        <></>
      )}

      <model-viewer
        src={path}
        style={{ height: "100%", width: "100%" }}
        ar
        camera-controls
        touch-action="pan-y"
        alt="A 3D model of an astronaut"
        xr-environment
      >
        <Button
          className="px-2 m-6 py-3 rounded-full bg-gray-100 hover:bg-gray-100"
          pill={true}
          slot="ar-button"
          onClick={() => setShowQR(!showQR)}
        >
          <Icon3dCubeSphere className="text-gray-900 p-0" />
        </Button>
      </model-viewer>
    </>
  );
}

function XRModel({ scale, path }) {
  const isPresenting = useXR((state) => state.isPresenting);

  return (
    // <Stage
    //   intensity={1}
    //   //   shadows="contact"
    //   center={[0, 1.6, -2]}
    //   shadows={{ type: "contact", opacity: 0.7, blur: 10 }}
    //   adjustCamera={1.75}
    //   environment="city"
    // >
    <>
      <GLTF
        scale={scale}
        position={isPresenting ? [0, 0.2, -3] : [0, 0, 0]}
        path={path}
      ></GLTF>
      {!isPresenting && (
        <ContactShadows
          position={[0, -scale / 2, 0]}
          scale={100}
          opacity={0.5}
          blur={0.8}
        />
      )}
    </>

    // </Stage>
  );
}

function GLTF(props) {
  const ref = useRef();

  const model = useGLTF(props.path);

  useLayoutEffect(() => {
    const box = new Box3().setFromObject(model.scene);
    var center = box.getCenter(new Vector3());
    var size = box.getSize(new Vector3());

    var maxAxis = Math.max(size.x, size.y, size.z);
    model.scene.scale.multiplyScalar(props.scale / maxAxis);

    box.setFromObject(model.scene);
    box.getCenter(center);
    box.getSize(size);

    model.scene.position.copy(center).multiplyScalar(-1);
  }, []);

  useEffect(() => {
    model.scene.traverse((i) => {
      i.castShadow = true;
      i.receiveShadow = true;
      if (i.material?.hasOwnProperty("side")) {
        i.material.side = FrontSide;
      }
      return i;
    });
  }, []);

  return <primitive ref={ref} {...props} dispose={null} object={model.scene} />;
}

// function FBX(props) {
//   const ref = useRef();

//   const model = useFBX("/car-scene/source/car scene.fbx");
//   console.log(model);

//   model.castShadow = true;
//   model.receiveShadow = true;

//   useLayoutEffect(() => {
//     const box = new Box3().setFromObject(model);
//     var center = box.getCenter(new Vector3());
//     var size = box.getSize(new Vector3());

//     var maxAxis = Math.max(size.x, size.y, size.z);
//     model.scale.multiplyScalar(props.scale / maxAxis);

//     box.setFromObject(model);
//     box.getCenter(center);
//     box.getSize(size);

//     console.log("b", box);

//     model.position.copy(center).multiplyScalar(-1);
//   }, []);

//   useEffect(() => {
//     model.children.forEach((i) => {
//       if (i.isMesh) {

//         i.material = phongToStandard(i.material);
//         if (i.material?.hasOwnProperty("side")) {
//           i.material.side = DoubleSide;
//         }

//         i.geometry.computeVertexNormals();
//         i.castShadow = true;
//         i.receiveShadow = true;

//       }
//       return i;
//     });
//   }, []);

//   return <primitive ref={ref} {...props} dispose={null} object={model} />;
// }
