import { apiSlice } from "../../app/api/apiSlice";

export const subcategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    subcategories: builder.query({
      query: () => ({
        url: "/api/subcategories",
      }),
      providesTags: ["Subcategory"],
    }),

    subcategoriesByCategory: builder.query({
      query: (category) => ({
        url: "/api/subcategories",
        params: {
          category_id: category
        }
      }),
      providesTags: ["Subcategory"],
    }),

    storeSubcategory: builder.mutation({
      query: (data) => ({
        url: "/api/subcategory/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Subcategory"],
    }),

    deleteSubcategory: builder.mutation({
      query: (id) => ({
        url: `/api/subcategory/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subcategory"],
    }),

    updateSubcategory: builder.mutation({
      query: (data) => ({
        url: `/api/subcategory/update/${data?.id}`,
        method: "POST",
        body: { name: data?.name, category_id: data?.category_id },
      }),
      invalidatesTags: ["Subcategory"],
    }),
  }),
});

export const {
  useSubcategoriesQuery,
  useSubcategoriesByCategoryQuery,
  useStoreSubcategoryMutation,
  useDeleteSubcategoryMutation,
  useUpdateSubcategoryMutation,
} = subcategoryApiSlice;
