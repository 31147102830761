import {
  Navigate,
  Outlet,
  redirect,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { QueryHandler } from "../../components/handlers";

import { useModelQuery } from "./modelApiSlice";
import { useEffect, useState } from "react";

import Viewer from "../../components/viewer";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";

const Categories = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [link, setLink] = useState(null);

  var user = useSelector(selectCurrentUser);

  const [searchParams] = useSearchParams();
  var basic = searchParams.get("api_token")
    ? searchParams.get("api_token")
    : user?.data?.api_token;

  const model = useModelQuery({ id, basic });

  useEffect(() => {
    if (model) {
      var l;
      l = "intent://arvr.google.com/scene-viewer/1.1";
      l += "?file=" + model?.data?.data.object;
      l += "&mode=ar_only";
      l += "&link=" + window.host?.replace("http://", "https://");
      l += "&title=" + model?.name;
      l +=
        "#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=" +
        window.host?.replace("http://", "https://") +
        ";end;";

       window.location.href = l;
    }
  }, [model, navigate]);

  return (
    <QueryHandler query={model}>
      <Outlet />
      <Viewer link={link} path={model.data?.data.object}></Viewer>
    </QueryHandler>
  );
};

export default Categories;
