import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/oauth/token",
        method: "POST",
        body: {
          username: credentials.email,
          password: credentials.password,
          grant_type: "password",
          scope: "",
          client_id: "2",
          client_secret: "k5MTo1CAstykkWcQLr65DHOgKki9d19tYQWfMy7f",
        },
      }),
    }),

    register: builder.mutation({
      query: (data) => ({
        url: "/register",
        method: "POST",
        body: { ...data },
      }),
    }),

    user: builder.mutation({
      query: () => ({
        url: "/api/user",
        method: "GET",
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useUserMutation } =
  authApiSlice;
