/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Label, Toast } from "flowbite-react";

import { HiClipboardCopy, HiHome } from "react-icons/hi";
import NavbarSidebarLayout from "../../components/layout/layouts/navbar-sidebar";

import { QueryHandler } from "../../components/handlers";
import { useModelQuery } from "./modelApiSlice";
import { useParams, useSearchParams } from "react-router-dom";
import Viewer from "../../components/viewer";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";
import { useState } from "react";


import EditModel from "./components/EditModel";
import { useEffect } from "react";

const EcommerceProductsPage = function () {
  let { id } = useParams();

  const [full, setFull] = useState(false);
 

  var user = useSelector(selectCurrentUser);

  const [searchParams] = useSearchParams();
  var basic = searchParams.get("api_token")
    ? searchParams.get("api_token")
    : user?.data?.api_token;

  const modelQ = useModelQuery({ id, basic });

  const model = modelQ.data?.data;

  const [url, setUrl] = useState(`${window.location.origin}/model/${model?.id}?api_token=${basic}`);

  useEffect(() => {
    if(model?.id){
      setUrl(`${window.location.origin}/model/${model?.id}?api_token=${basic}`);
    }
  }, [model])

  const iframe = `<iframe src="${url}" width="100%" height="100%" frameborder="0" allowfullscreen="1"></iframe>`;

  return (
    <NavbarSidebarLayout isFooter={false}>
      <QueryHandler query={modelQ}>
        <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/categories">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl" />
                    <span className="dark:text-white">Home</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/models">Models</Breadcrumb.Item>
                <Breadcrumb.Item>Model {model?.id}</Breadcrumb.Item>
              </Breadcrumb>
          
              {full ? (
                <div style={{ height: "80vh", position: "relative" }}>
                  <Viewer token={basic} setFull={setFull} path={model?.object}></Viewer>
                </div>
              ) : (
                <section class="text-gray-600 body-font overflow-hidden">
                  <div class="container px-5 py-12 pb-24 mx-auto">
                    <div class="flex flex-wrap -m-12">
                      <div class="p-12 md:w-1/2 flex flex-col items-start">
                        <span class="inline-block py-1 px-2 rounded bg-indigo-50 text-indigo-500 text-xs font-medium tracking-widest">
                          {model?.subcategory.name}
                        </span>
                        <h2 class="sm:text-3xl text-2xl title-font font-medium text-gray-900 mt-4 mb-4">
                          {model?.name}
                        </h2>
                        <code class="leading-relaxed mb-8">{iframe}</code>
                        <div class="flex items-center flex-wrap pb-4 mb-4  mt-auto w-full">
                          <Button
                            color="warning "
                            onClick={() => {
                              navigator.clipboard.writeText(iframe);
                              alert("Copied to clipboard");
                            }}
                          >
                            <HiClipboardCopy className="mr-2 text-lg text-orange-700" />
                            Copy Iframe
                          </Button>

                           <EditModel item={model}></EditModel>

                          <a
                            href={`../model/${model?.id}`}
                            class="text-indigo-500 inline-flex items-center ml-6"
                          >
                            Model
                            <svg
                              class="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                            </svg>
                          </a>
                        </div>
                        <a class="inline-flex items-center">
                          <img
                            alt="blog"
                            src={model?.thumbnail}
                            class="w-64 rounded flex-shrink-0 object-cover object-center"
                          />
                        </a>
                      </div>
                      <div class="rounded md:w-1/2 flex flex-col items-start border-2 border-gray-100 relative">
                        <Viewer full={full} setFull={setFull} vr={false} path={model?.object}></Viewer>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </QueryHandler>
    </NavbarSidebarLayout>
  );
};

export default EcommerceProductsPage;
