import { apiSlice } from "../../app/api/apiSlice";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    categories: builder.query({
      query: () => ({
        url: "/api/categories",
      }),
      providesTags: ["Category"],
    }),
    storeCategory: builder.mutation({
      query: (data) => ({
        url: "/api/category/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Category"],
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/api/category/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),

    updateCategory: builder.mutation({
      query: (data) => ({
        url: `/api/category/update/${data?.id}`,
        method: "POST",
        body: {name: data?.name},
      }),
      invalidatesTags: ["Category"],
    }),
  }),
});

export const {
  useCategoriesQuery,
  useStoreCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation
} = categoryApiSlice;
