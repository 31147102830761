import { Navigate, Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { QueryHandler } from "../../components/handlers";

import { useModelQuery } from "./modelApiSlice";
import { useEffect, useState } from "react";

import Viewer from "../../components/viewer";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";


const Categories = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  

  
  const [link, setLink] = useState(null);
  
  var user = useSelector(selectCurrentUser);
  
  const [searchParams] = useSearchParams();
  var basic = searchParams.get("api_token") ? searchParams.get("api_token") :  user?.data?.api_token;
  var ar = searchParams.get("ar");

  const model = useModelQuery({id, basic});

  useEffect(() => {
    var l;
    l = "intent://arvr.google.com/scene-viewer/1.1";
    l += "?file=" + model?.data?.data.object;
    l += "&mode=ar_only";
    l += "&link=" + "https://yolo-way.com";
    l += "&title=" + model?.data?.data.name;
    l +=
      "#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=" +
      window.location.href?.replace("http://", "https://") +
      ";end;";

    if(ar == "1" && model?.data?.data.object) {
      window.location.href = l;
    }

    setLink(l);

  }, [model]);

  return (
    <QueryHandler query={model}>
      <Outlet />
      <Viewer token={basic} link={link} path={model.data?.data.object} id={model?.data?.data.id}></Viewer>
    </QueryHandler>
  );
};

export default Categories;
