/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Label,
  Modal,
  Table,
  TextInput,
} from "flowbite-react";

import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import {
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
} from "react-icons/hi";
import NavbarSidebarLayout from "../../components/layout/layouts/navbar-sidebar";
import {
  useCategoriesQuery,
  useDeleteCategoryMutation,
  useStoreCategoryMutation,
  useUpdateCategoryMutation,
} from "./categoryApiSlice";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Form, FormikControl } from "../../components/form";

import * as Yup from "yup";
import { useRef } from "react";

const EcommerceProductsPage = function () {
  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/categories">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/categories">
                Categories
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Categories
            </h1>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-start">
              <AddProductModal />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="m-5">
              <ProductsTable />
            </div>
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
};

const AddProductModal = function () {
  const [isOpen, setOpen] = useState(false);

  const [storeCategory, { error: storeError }] = useStoreCategoryMutation();

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const isDone = await storeCategory(values);
    if (isDone?.data) setOpen(false);
    return isDone?.data;
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(!isOpen)}>
        <FaPlus className="mr-3 text-sm" />
        Add Category
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <Form
          initialValues={{ name: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          reset
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Add Category</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
              <FormikControl
                control="input"
                type="text"
                label="Category"
                name="name"
              />
              {storeError ? <p>{storeError.data?.message}</p> : <></>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" type="submit">
              Add Category
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const EditProductModal = function ({ item }) {
  const [isOpen, setOpen] = useState(false);

  console.log("I", item);
  const [updateCategory, { error: updateError }] = useUpdateCategoryMutation();

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const isDone = await updateCategory(values);
    if (isDone?.data) setOpen(false);
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className="mr-2 text-lg" />
        Edit item
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <Form
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Category</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
              <FormikControl
                control="input"
                type="text"
                label="Category"
                name="name"
              />
              {updateError ? <p>{updateError.data?.message}</p> : <></>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" type="submit">
              Edit Category
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const DeleteProductModal = function ({ item }) {
  const [isOpen, setOpen] = useState(false);

  const [deleteCategory] = useDeleteCategoryMutation();

  const del = () => {
    deleteCategory(item?.id);
    setOpen(false);
  };

  return (
    <>
      <Button color="failure" onClick={() => setOpen(!isOpen)}>
        <HiTrash className="mr-2 text-lg" />
        Delete item
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
        <Modal.Header className="px-3 pt-3 pb-0">
          <span className="sr-only">Delete product</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-600" />
            <p className="text-lg text-gray-500 dark:text-gray-300">
              Are you sure you want to delete this product?
            </p>
            <div className="flex items-center gap-x-3">
              <Button color="failure" onClick={del}>
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setOpen(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ProductsTable = function () {
  const categories = useCategoriesQuery();

  const renderCategory = useCallback(
    (category) => (
      <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
        <Table.Cell className="whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
          <div className="text-base font-semibold text-gray-900 dark:text-white">
            {category?.name}
          </div>
        </Table.Cell>

        <Table.Cell className="space-x-2 whitespace-nowrap p-4">
          <div className="flex items-center gap-x-3">
            <EditProductModal item={category} />
            <DeleteProductModal item={category} />
          </div>
        </Table.Cell>
      </Table.Row>
    ),
    []
  );

  const renderCategories = categories.data?.data.map(renderCategory);

  return (
    <Table className="min-w-full p-5 divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700 ">
        <Table.HeadCell>Category Name</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {renderCategories}
      </Table.Body>
    </Table>
  );
};

export default EcommerceProductsPage;
