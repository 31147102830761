import { apiSlice } from "../../app/api/apiSlice";

export const modelApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    models: builder.query({
      query: (params) => ({
        url: "/api/prototypes",
        params,
      }),
      providesTags: ["Prototype"],
    }),
    model: builder.query({
      query: ({ id, basic }) => ({
        url: `/api/prototype/${id}`,
        params: {
          api_token: basic,
        },
      }),
      providesTags: ["Prototype"],
    }),
    storeModel: builder.mutation({
      query: (data) => ({
        url: "/api/prototype/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Prototype"],
    }),

    deleteModel: builder.mutation({
      query: (id) => ({
        url: `/api/prototype/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Prototype"],
    }),

    updateModel: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/api/prototype/update/${id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Prototype"],
    }),
  }),
});

export const {
  useModelsQuery,
  useModelQuery,
  useStoreModelMutation,
  useDeleteModelMutation,
  useUpdateModelMutation,
} = modelApiSlice;
