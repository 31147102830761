import React from "react";
import { Formik, Form } from "formik";

function MyForm(props) {
  const { initialValues, validationSchema, onSubmit, children, reset } = props;

  const submit = async (values, { resetForm }) => {
    if (await onSubmit(values)) resetForm({ values: initialValues });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={reset ? submit : onSubmit}
    >
      <Form encType="multipart/form-data">{children}</Form>
    </Formik>
  );
}

export default MyForm;
