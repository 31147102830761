/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal, Spinner } from "flowbite-react";

import { useState } from "react";

import { HiPencilAlt } from "react-icons/hi";

import { useCategoriesQuery } from "../../categories/categoryApiSlice";

import { Form } from "../../../components/form";

import * as Yup from "yup";

import { QueryHandler } from "../../../components/handlers";
import { useUpdateModelMutation } from "../modelApiSlice";
import { useSubcategoriesByCategoryQuery } from "../../subcategories/subcategoryApiSlice";

import { Inputs } from "./AddModel";

function isValid(fileName, fileType) {
    const types = {
      image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
      object: ["zip", "gltf", "glb"],
    };
    return fileName
      ? types[fileType].indexOf(fileName.split(".").pop()) > -1
      : true;
  }


const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    subcategory_id: Yup.number().required("Required"),
    fthumbnail: Yup.mixed().test(
      "is-valid-type",
      "Not a valid image type",
      (value) => isValid(value?.name.toLowerCase(), "image")
    ),
    fobject: Yup.mixed().test("is-valid-type", "Not a valid 3d type", (value) =>
      isValid(value?.name.toLowerCase(), "object")
    ),
  });

export const EditModel = function ({ item }) {
  const [isOpen, setOpen] = useState(false);


  const [updateModel, { error: updateError, isLoading }] =
    useUpdateModelMutation();

  const categories = useCategoriesQuery();
  const [category, setCategory] = useState(item?.subcategory.category.id);
  const subcategories = useSubcategoriesByCategoryQuery(category);
 
  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("subcategory_id", values.subcategory_id);

    values.fobject && formData.append("object", values.fobject);
    values.fthumbnail && formData.append("thumbnail", values.fthumbnail);
    formData.append("_method", "POST");

    const isDone = await updateModel({ id: item?.id, data: formData });
    if (isDone?.data) setOpen(false);
    resetForm({ values: { fobject: null, fthumbnail: null } });
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className="mr-2 text-lg" />
        Edit item
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <QueryHandler query={subcategories}>
          <QueryHandler query={categories}>
            <Form
              initialValues={{
                name: item?.name,
                subcategory_id: item?.subcategory.id,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                <strong>Edit Model</strong>
              </Modal.Header>
              <Modal.Body>
                <Inputs
                  categories={categories}
                  subcategories={subcategories}
                  error={updateError}
                  setCategory={setCategory}
                  category={category}
                ></Inputs>
              </Modal.Body>

              <Modal.Footer>
                {isLoading ? (
                  <Spinner aria-label="Default status example" />
                ) : (
                  <Button color="primary" type="submit">
                    Edit Model
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </QueryHandler>
        </QueryHandler>
      </Modal>
    </>
  );
};

export default EditModel;
