import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { store } from "./app/store";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Flowbite } from "flowbite-react";

import theme from "./flowbite-theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
      <Flowbite theme={{ theme }}>
        <BrowserRouter >
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
        </Flowbite>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
);
