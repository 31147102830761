/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Label,
  Modal,
  Select,
  Table,
  TextInput,
} from "flowbite-react";

import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import {
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
} from "react-icons/hi";
import NavbarSidebarLayout from "../../components/layout/layouts/navbar-sidebar";
import {
  useCategoriesQuery,
  useDeleteCategoryMutation,
  useStoreCategoryMutation,
  useUpdateCategoryMutation,
} from "../categories/categoryApiSlice";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Form, FormikControl } from "../../components/form";

import * as Yup from "yup";
import { useRef } from "react";
import {
  useDeleteSubcategoryMutation,
  useStoreSubcategoryMutation,
  useSubcategoriesByCategoryQuery,
  useSubcategoriesQuery,
  useUpdateSubcategoryMutation,
} from "./subcategoryApiSlice";
import { QueryHandler } from "../../components/handlers";

const Page = function () {
  const [category, setCategory] = useState('');

  const categories = useCategoriesQuery();

  const renderCategory = useCallback(
    (category) => (
      <option key={category?.id} value={category?.id}>
        {category?.name}
      </option>
    ),
    []
  );

  const renderCategories = [{ id: '', name: "All" }]
    .concat(categories.data?.data)
    .map(renderCategory);

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/categories">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/subcategories">
                Subcategories
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Subcategories
            </h1>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-start">
              <AddProductModal />

              <Select
                control="select"
                label="Category"
                className="ml-4"
                name="category_id"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                value={category}
              >
                {renderCategories}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="m-5">
              <ProductsTable category={category} />
            </div>
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
};

const AddProductModal = function () {
  const [isOpen, setOpen] = useState(false);

  const [storeSubcategory, { error: storeError }] =
    useStoreSubcategoryMutation();
  const categories = useCategoriesQuery();

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    category_id: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const isDone = await storeSubcategory(values);
    if (isDone?.data) setOpen(false);
    return isDone?.data;
  };

  const renderCategory = useCallback(
    (category) => (
      <option key={category?.id} value={category?.id}>
        {category?.name}
      </option>
    ),
    []
  );

  const renderCategories = [].concat(categories.data?.data).map(renderCategory);

  return (
    <>
      <Button color="primary" onClick={() => setOpen(!isOpen)}>
        <FaPlus className="mr-3 text-sm" />
        Add Subategory
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <QueryHandler query={categories}>
          <Form
            initialValues={{
              name: "",
              category_id: categories.data?.data[0]?.id,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            reset
          >
            <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
              <strong>Add Subategory</strong>
            </Modal.Header>
            <Modal.Body>
              <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
                <FormikControl
                  control="input"
                  type="text"
                  label="Name"
                  name="name"
                />

                <FormikControl
                  control="select"
                  label="Category"
                  name="category_id"
                >
                  {renderCategories}
                </FormikControl>

                {storeError && (
                  <p className=" text-sm font-medium text-red-700">
                    {storeError.data?.message}
                  </p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button color="primary" type="submit">
                Add Subategory
              </Button>
            </Modal.Footer>
          </Form>
        </QueryHandler>
      </Modal>
    </>
  );
};

const EditProductModal = function ({ item }) {
  const [isOpen, setOpen] = useState(false);

  const [updateSubcategory, { error: updateError }] =
    useUpdateSubcategoryMutation();
  const categories = useCategoriesQuery();

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    category_id: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const isDone = await updateSubcategory(values);
    if (isDone?.data) setOpen(false);
  };

  const renderCategory = useCallback(
    (category) => (
      <option key={category?.id} value={category?.id}>
        {category?.name}
      </option>
    ),
    []
  );

  const renderCategories = [].concat(categories.data?.data).map(renderCategory);

  return (
    <>
      <Button color="primary" onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className="mr-2 text-lg" />
        Edit item
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <Form
          initialValues={{
            category_id: item.category.id,
            name: item.name,
            id: item.id,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Subcategory</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
              <FormikControl
                control="input"
                type="text"
                label="Category"
                name="name"
              />

              <FormikControl
                control="select"
                label="Category"
                name="category_id"
              >
                {renderCategories}
              </FormikControl>

              {updateError && (
                <p className=" text-sm font-medium text-red-700">
                  {updateError.data?.message}
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" type="submit">
              Edit Subcategory
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const DeleteProductModal = function ({ item }) {
  const [isOpen, setOpen] = useState(false);

  const [deleteSubategory] = useDeleteSubcategoryMutation();

  const del = () => {
    deleteSubategory(item?.id);
    setOpen(false);
  };

  return (
    <>
      <Button color="failure" onClick={() => setOpen(!isOpen)}>
        <HiTrash className="mr-2 text-lg" />
        Delete item
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
        <Modal.Header className="px-3 pt-3 pb-0">
          <span className="sr-only">Delete product</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-600" />
            <p className="text-lg text-gray-500 dark:text-gray-300">
              Are you sure you want to delete this product?
            </p>
            <div className="flex items-center gap-x-3">
              <Button color="failure" onClick={del}>
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setOpen(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ProductsTable = function ({category}) {
  const subcategories = useSubcategoriesByCategoryQuery(category);

  const renderCategory = useCallback(
    (subcategory) => (
      <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
        <Table.Cell className="whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
          <div className="text-base font-semibold text-gray-900 dark:text-white">
            {subcategory.name}
          </div>
          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {subcategory.category?.name}
          </div>
        </Table.Cell>

        <Table.Cell className="space-x-2 whitespace-nowrap p-4">
          <div className="flex items-center gap-x-3">
            <EditProductModal item={subcategory} />
            <DeleteProductModal item={subcategory} />
          </div>
        </Table.Cell>
      </Table.Row>
    ),
    []
  );

  const renderSubategories = subcategories.data?.data.map(renderCategory);

  return (
    <Table className="min-w-full p-5 divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700 ">
        <Table.HeadCell>Name & Category</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {renderSubategories}
      </Table.Body>
    </Table>
  );
};

export default Page;
