import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { setCrendentials, logOut } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://arwp.tachyonstudio.com/",
  credentials: "include",
  // mode: "no-cors",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Accept", "application/json");
      // headers.set("Content-Type", 'multipart/form-data');
    }
    return headers;
  },
});

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions);
//   if (result?.error?.originalStatus === 400) {
//     const refreshResult = await baseQuery("/refresh", api, extraOptions);
//     if (refreshResult?.data) {
//       const user = api.getState().auth.user;
//       api.dispatch(setCrendentials(...refreshResult.data, user));
//       result = await baseQuery(args, api, extraOptions);
//     }else{
//         api.dispatch(logOut());
//     }
//   }
//   return result;
// };

export const apiSlice = createApi({
    baseQuery: baseQuery,
    endpoints: builder => ({})
})