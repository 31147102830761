/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Label,
  Modal,
  Select,
  Spinner,
  Table,
} from "flowbite-react";

import { useState } from "react";

import {
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
} from "react-icons/hi";
import NavbarSidebarLayout from "../../components/layout/layouts/navbar-sidebar";
import { useCategoriesQuery } from "../categories/categoryApiSlice";

import { useCallback } from "react";
import { Form, FormikControl } from "../../components/form";

import * as Yup from "yup";

import { QueryHandler } from "../../components/handlers";
import {
  useDeleteModelMutation,
  useModelsQuery,
  useUpdateModelMutation,
} from "./modelApiSlice";
import { useSubcategoriesByCategoryQuery } from "../subcategories/subcategoryApiSlice";

import AddModel from "./components/AddModel";
import { Link } from "react-router-dom";
import EditModel from "./components/EditModel";
import DeleteModel from "./components/DeleteModel";

const Page = function () {
  const categories = useCategoriesQuery();
  const [category, setCategory] = useState("");
  const subcategories = useSubcategoriesByCategoryQuery(category);
  const [subcategory, setSubcategory] = useState("");

  const renderItem = useCallback(
    (item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ),
    []
  );

  const renderSubcategories = [{ id: "", name: "All" }]
    .concat(subcategories.data?.data)
    .map(renderItem);

  const renderCategories = [{ id: "", name: "All" }]
    .concat(categories.data?.data)
    .map(renderItem);

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/categories">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/models">Models</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Models
            </h1>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-start">
              <AddModel />

              <div className="ml-auto mr-20 flex">
                <div className="ml-4">
                  <Label value="Category"></Label>

                  <Select
                    control="select"
                    label="Category"
                    name="category_id"
                    onChange={(e) => {
                      setSubcategory('');
                      setCategory(e.target.value);
                    }}
                    value={category}
                  >
                    {renderCategories}
                  </Select>
                </div>
                <div className="ml-4">
                  <Label value="Subategory"></Label>
                  <Select
                    control="select"
                    label="Subcategory"
                    className=""
                    name="subcategory_id"
                    onChange={(e) => {
                      setSubcategory(e.target.value);
                    }}
                    value={subcategory}
                  >
                    {renderSubcategories}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="m-5">
              <ProductsTable category={category} subcategory={subcategory} />
            </div>
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
};

const ProductsTable = function ({ category, subcategory }) {
  const models = useModelsQuery({
    category_id: category,
    subcategory_id: subcategory,
  });

  const renderCategory = useCallback(
    (model) => (
      <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
        <Link to={`${model.id}`}>
          <Table.Cell className="mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0">
            <img
              className="h-12 w-12 rounded object-cover"
              src={model.thumbnail}
              alt={model.name}
            />
            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
              <div className="text-base font-semibold text-gray-900 dark:text-white">
                {model.name}
              </div>
              <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                {model.subcategory?.name}
              </div>
            </div>
          </Table.Cell>
        </Link>

        <Table.Cell className="whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
          {model.status === 1 ? (
            <div className="flex items-center">
              <div className="mr-2 h-2.5 w-2.5 rounded-full bg-green-400"></div>{" "}
              Active
            </div>
          ) : (
            <div className="flex items-center">
              <div className="mr-2 h-2.5 w-2.5 rounded-full bg-red-500"></div>{" "}
              Offline
            </div>
          )}
        </Table.Cell>

        <Table.Cell className="space-x-2 whitespace-nowrap p-4">
          <div className="flex items-center gap-x-3">
            <EditModel item={model} />
            <DeleteModel item={model} />
          </div>
        </Table.Cell>
      </Table.Row>
    ),
    []
  );

  const renderModels = models.data?.data.map(renderCategory);

  return (
    <Table className="min-w-full p-5 divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700 ">
        <Table.HeadCell>Name & Subcategory</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {renderModels}
      </Table.Body>
    </Table>
  );
};

export default Page;
