import React from "react";
import { Field, ErrorMessage, useField } from "formik";
import TextError from "./TextError";

import { useFormikContext } from "formik";
import { HiDocument, HiUpload } from "react-icons/hi";

function Input(props) {
  const { label, name, description, ...rest } = props;

  const { values, setFieldValue, errors } = useFormikContext();

  const [field] = useField(props);

  const handleFileChange = (event) => {
    setFieldValue("f" + field.name, event.currentTarget.files[0]);
  };

  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>

      <div className="lg:col-span-2">
        <div className="flex w-full items-center justify-center">
          <label className="flex h-32 w-full cursor-pointer flex-col rounded border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              {values["f" + name] ? (
                <>
                  <HiDocument className="text-4xl text-gray-300" />
                  <p className="py-1 text-sm text-gray-600 dark:text-gray-500">
                    {values["f" + name].name}
                  </p>
                  {errors["f" + name] ? (
                    <p className="text-xs text-red-800 dark:text-gray-400">
                      {errors["f" + name]}
                    </p>
                  ) : (
                    <p className="text-xs text-green-800 dark:text-gray-400">
                      Accepted
                    </p>
                  )}
                </>
              ) : (
                <>
                  <HiUpload className="text-4xl text-gray-300" />
                  <p className="py-1 text-sm text-gray-600 dark:text-gray-500">
                    Upload a file
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {description}
                  </p>
                </>
              )}
            </div>
            <Field
              value={values[name]}
              id={name}
              type="file"
              className="hidden"
              onChange={handleFileChange}
              name={name}
              {...rest}
            />
          </label>
        </div>
      </div>
      {!values["f" + name] ? (
        errors["f" + name] && <TextError>{errors["f" + name]}</TextError>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Input;
