import { createSlice } from "@reduxjs/toolkit";
import { bake_cookie, read_cookie, delete_cookie } from "sfcookies";

const initialState = read_cookie("auth")
  ? { user: read_cookie("user"), token: read_cookie("auth") }
  : { user: null, token: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      bake_cookie("auth", action.payload);
      state.token = action.payload;
    },
    setUser: (state, action) => {
      bake_cookie("user", action.payload);
      state.user = action.payload;
    },
    logOut: (state, action) => {
      delete_cookie("auth");
      delete_cookie("user");
      state.user = null;
      state.token = null;
    },
  },
});

export const { setToken, setUser, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
