/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal, Spinner } from "flowbite-react";

import { useState } from "react";
import { FaPlus } from "react-icons/fa";

import { useCategoriesQuery } from "../../categories/categoryApiSlice";

import { useCallback } from "react";
import { Form, FormikControl } from "../../../components/form";

import * as Yup from "yup";

import { QueryHandler } from "../../../components/handlers";
import { useStoreModelMutation } from "../modelApiSlice";
import { useSubcategoriesByCategoryQuery } from "../../subcategories/subcategoryApiSlice";
import { useFormikContext } from "formik";

export function isValid(fileName, fileType) {
  const types = {
    image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
    object: ["zip", "gltf", "glb"],
  };
  return fileName && types[fileType].indexOf(fileName.split(".").pop()) > -1;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  subcategory_id: Yup.number().required("Required"),
  fthumbnail: Yup.mixed()
    .required("Required")
    .test("is-valid-type", "Not a valid image type", (value) =>
      isValid(value?.name.toLowerCase(), "image")
    ),
  fobject: Yup.mixed()
    .required("Required")
    .test("is-valid-type", "Not a valid 3d type", (value) =>
      isValid(value?.name.toLowerCase(), "object")
    ),
});

const AddModel = function () {
  const [isOpen, setOpen] = useState(false);

  const [storeModel, { error: storeError, isLoading }] =
    useStoreModelMutation();

  const categories = useCategoriesQuery();
  const [category, setCategory] = useState(categories?.data?.data[0]?.id);
  const subcategories = useSubcategoriesByCategoryQuery(category);

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("subcategory_id", values.subcategory_id);
    formData.append("object", values.fobject);
    formData.append("thumbnail", values.fthumbnail);
    formData.append("_method", "POST");

    const isDone = await storeModel(formData);
    if (isDone?.data) setOpen(false);
    return isDone?.data;
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(!isOpen)}>
        <FaPlus className="mr-3 text-sm" />
        Add Model
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <QueryHandler query={subcategories}>
          <QueryHandler query={categories}>
            <Form
              initialValues={{
                name: "",
                fthumbnail: null,
                fobject: null,
                subcategory_id: subcategories.data?.data[0]?.id,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              reset
            >
              <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                <strong>Add Model</strong>
              </Modal.Header>
              <Modal.Body>
                <Inputs
                  categories={categories}
                  subcategories={subcategories}
                  error={storeError}
                  setCategory={setCategory}
                  category={category}
                ></Inputs>
              </Modal.Body>

              <Modal.Footer>
                {isLoading ? (
                  <Spinner aria-label="Default status example" />
                ) : (
                  <Button color="primary" type="submit">
                    Add Model
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </QueryHandler>
        </QueryHandler>
      </Modal>
    </>
  );
};

export const Inputs = function ({
  categories,
  subcategories,
  error,
  setCategory,
  category,
}) {
  const renderItem = useCallback(
    (item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ),
    []
  );

  const renderSubcategories = [{ id: -1, name: "-" }]
    .concat(subcategories.data?.data)
    .map(renderItem);

  const renderCategories = [].concat(categories.data?.data).map(renderItem);

  const { setFieldValue } = useFormikContext();

  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
      <FormikControl control="input" type="text" label="Name" name="name" />

      <FormikControl
        control="select"
        label="Category"
        name="category_id"
        onChange={(e) => {
          setFieldValue("subcategory_id", -1);
          setCategory(e.target.value);
        }}
        value={category}
      >
        {renderCategories}
      </FormikControl>

      <FormikControl control="select" label="Subcategory" name="subcategory_id">
        {renderSubcategories}
      </FormikControl>

      <FormikControl
        description="GLTF, GLB, ZIP up to 100MB"
        control="file"
        label="Object"
        name="object"
      />
      <FormikControl
        control="file"
        label="Thumbnail"
        name="thumbnail"
        description="PNG, JPG, JPEG, SVG up to 5MB"
      />

      {error && (
        <p className=" text-sm font-medium text-red-700">
          {error.data?.message}
        </p>
      )}
    </div>
  );
};

export default AddModel;
