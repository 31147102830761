import React from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import Select from "./Select";
import File from "./File";


function FormikControl(props) {
  const { control, children, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest}>{children}</Select>;
      case "file":
        return <File {...rest}>{children}</File>;
    default:
      return null;
  }
}

export default FormikControl;
