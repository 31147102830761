import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import TextError from "./TextError";
import { Label, Select } from "flowbite-react";

function Input(props) {
  const { label, name, children, ...rest } = props;

  const { values, setFieldValue, errors } = useFormikContext();

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <div className="form-control">
      <Label htmlFor={name}>{label}</Label>

      <Select
        id={name}
        name={name}
        value={values[name]}
        onChange={handleChange}
        {...rest}
      >
        {children}
      </Select>

      {errors[name] && <TextError>{errors[name]}</TextError>}
    </div>
  );
}

export default Input;
