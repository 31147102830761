import { Sidebar, TextInput } from "flowbite-react";

import { useEffect, useState } from "react";

import {

  Category,
  CubeSend,

  Home,
  Tag,
} from "tabler-icons-react";

const ExampleSidebar = function () {
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
  }, [setCurrentPage]);

  return (
    <Sidebar aria-label="Sidebar with multi-level dropdown example">
      <div className="flex h-full flex-col justify-between py-2">
        <div>
          <form className="pb-3 md:hidden">
            <TextInput
              icon={Home}
              type="search"
              placeholder="Search"
              required
              size={32}
            />
          </form>
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              {/* <Sidebar.Item
                href="/"
                icon={Home}
                className={
                  "/" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
                }
              >
                Home
              </Sidebar.Item> */}
              <Sidebar.Item
                href="/categories"
                icon={Category}
                className={
                  "/e-commerce/products" === currentPage
                    ? "bg-gray-100 dark:bg-gray-700"
                    : ""
                }
              >
                Categories
              </Sidebar.Item>
              <Sidebar.Item
                href="subcategories"
                icon={Tag}
                className={
                  "/users/list" === currentPage
                    ? "bg-gray-100 dark:bg-gray-700"
                    : ""
                }
              >
                Subcategories
              </Sidebar.Item>

              <Sidebar.Item
                href="/models"
                icon={CubeSend}
                className={
                  "/users/list" === currentPage
                    ? "bg-gray-100 dark:bg-gray-700"
                    : ""
                }
              >
                Models
              </Sidebar.Item>
              {/* <Sidebar.Item href="/authentication/sign-in" icon={Activity}>
                Sign in
              </Sidebar.Item>
              <Sidebar.Item href="/authentication/sign-up" icon={Activity}>
                Sign up
              </Sidebar.Item> */}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </div>
      </div>
    </Sidebar>
  );
};

export default ExampleSidebar;
