import { Spinner } from "flowbite-react";

const QueryHandler = ({ query, children }) => {
  let content;
  if (query.isLoading) {
    content = (
      <div className="flex flex-1 h-full justify-center items-center">
  
        <Spinner aria-label="Default status example" />
      </div>
    );
  } else if (query.isSuccess) {
    content = children;
  } else if (query.isError) {
    content = <p>Error: {query.error.message}</p>;
  }
  return content;
};

export default QueryHandler;
