import { Routes, Route } from "react-router-dom";

import Layout from "./components/layout";
import Login from "./features/auth/Login";
import RequireAuth from "./features/auth/RequireAuth";

import Categories from "./features/categories/Categories";

import Subcategories from "./features/subcategories/Subcategories";

import Test from "./components/viewer";

import Models from "./features/models/Models";
import Model from "./features/models/Model";
import ModelAr from "./features/models/ModelAr";
import ModelView from "./features/models/ModelView";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index path="/login" element={<Login />} />

        <Route element={<RequireAuth />}>
          <Route path="/categories" element={<Categories />}/>
          <Route path="/subcategories" element={<Subcategories />} />

          <Route path="/test" element={<Test />}></Route>

          <Route path="/model/:id" element={<ModelView />} />
          <Route path="/model/ar/:id" element={<ModelAr />} />
          

          <Route path="/models" element={<Models />} />
          <Route path="/models/:id" element={<Model />} />


        </Route>
      </Route>
    </Routes>
  );
}

export default App;
