import React, { useEffect } from "react";
import * as Yup from "yup";
import { FormikControl, Form } from "../../components/form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoginMutation, useUserMutation } from "./authApiSlice";
import { setToken, setUser } from "./authSlice";
import { selectCurrentToken } from "./authSlice";

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Card,
  Checkbox,
  Label,
  Spinner,
  TextInput,
} from "flowbite-react";

function SignInPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken);

  useEffect(() => {
    if (token && token.length > 0) {
      navigate("/categories", { replace: true });
    }
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const [login, { isLoading, error }] = useLoginMutation();
  const [user, { userError }] = useUserMutation();

  const validationSchema = Yup.object({
    email: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const auth = await login(values).unwrap();
    if (!error) {
      dispatch(setToken(auth.access_token));

      const getUser = await user().unwrap();
      dispatch(setUser(getUser));

      navigate("/categories");
      resetForm();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <div className="my-6 flex items-center gap-x-1 lg:my-0">
        <img
          alt="Flowbite logo"
          src="https://flowbite.com/docs/images/logo.svg"
          className="mr-3 h-12"
        />
        <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
          Flowbite
        </span>
      </div>
      <Card
        horizontal
       
        className="w-full md:max-w-screen-sm [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
      >
        <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
          Sign in to platform
        </h1>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <div className="mb-4 flex flex-col gap-y-3">
            <FormikControl
              control="input"
              type="text"
              placeholder="name@company.com"
              label="Your Email"
              name="email"
            />
          </div>
          <div className="mb-6 flex flex-col gap-y-3">
            <FormikControl
              control="input"
              type="password"
              placeholder="••••••••"
              label="Your Password"
              name="password"
            />
          </div>

          {error && (
            <p className="font-medium mb-5 text-sm text-red-700">
              {error.data?.message}
            </p>
          )}
          <div className="mb-6 flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <Checkbox id="rememberMe" name="rememberMe" />
              <Label htmlFor="rememberMe">Remember me</Label>
            </div>
            {/* <a
              href="#"
              className="w-1/2 text-right text-sm text-primary-600 dark:text-primary-300"
            >
              Lost Password?
            </a> */}
          </div>

          

          <div className="mb-6">
            {isLoading ? (
              <Spinner aria-label="Default status example" />
            ) : (
              <Button type="submit" className="w-full lg:w-auto">
                Login to your account
              </Button>
            )}
          </div>
          {/* <p className="text-sm text-gray-500 dark:text-gray-300">
            Not registered?&nbsp;
            <a href="#" className="text-primary-600 dark:text-primary-300">
              Create account
            </a>
          </p> */}
        </Form>
      </Card>
    </div>
  );
}

export default SignInPage;
